import { useLocation } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import { dequal } from "dequal";
import posthog from "posthog-js";
import { useCallback, useEffect, useRef } from "react";

export type TrackEventFunction<T> = (eventName: string, properties: T) => void;

export function useAnalytics() {
  const location = useLocation();
  const setUser = useCallback((user: Sentry.User | null) => {
    Sentry.setUser(user);
    if (user && user.id) {
      posthog.identify(user.id as string, {
        ...user,
        //  Readd these to keep consistency with previous PR
        userEmail: user.email,
        user_id: user.id,
      });
    }
  }, []);

  const clearUser = useCallback(() => {
    Sentry.setUser(null);
    posthog.reset();
  }, []);

  const trackEvent: TrackEventFunction<Record<string, any>> = useCallback(
    (eventName, properties = {}) => {
      const eventBody = {
        page: location.pathname,
        ...properties,
      };
      try {
        posthog.capture(eventName, eventBody);
      } catch (error) {
        Sentry.captureException(error, {
          level: "warning",
          extra: {
            eventName,
            eventBody,
          },
        });
      }
    },
    [location],
  );

  return {
    setUser,
    clearUser,
    trackEvent,
  };
}

export function useAnalyticsSetUser(user?: Sentry.User) {
  const { setUser } = useAnalytics();
  const prevUser = useRef<Sentry.User | null>();
  useEffect(() => {
    if (!dequal(prevUser.current, user)) {
      prevUser.current = user;
      setUser(user ?? null);
    }
  }, [prevUser, setUser, user]);
}
